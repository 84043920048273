export class API_CONFIG {
    public static get BASE_URL() {
        return {
            DEV: process.env.NEXT_PUBLIC_DEV_API_URL ?? "DEVURLNOTDETECTED",
            STAGING: process.env.NEXT_PUBLIC_STG_API_URL ?? "STGURLNOTDETECTED",
            PROD: process.env.NEXT_PUBLIC_PROD_API_URL ?? "PRODURLNOTDETECTED",
            API_URL: process.env.NEXT_PUBLIC_API_URL ?? "API_URL_NOT_DETECTED_2",
        }
    };
    public static get WELCOME_PHRASES() {
        return{
            "en": "Welcome to EON",
            "es": "Bienvenido a EON"
        }
    }

    public static get ENDPOINTS() {
        return {
            MOCK: {
                GET_HISTORY: '/getChatHistory',
                GET_FILES: '/getFileCollection',
                GET_UPLOADING_FILES: '/getUploadingFiles',
                GET_CHAT_MESSAGES: '/getChatMessages',
                SEND_MESSAGE: '/Mock/sendMessage',
                UPLOAD_FILE: '/Mock/uploadFile',
            },
            CHAT: {
                GET_HISTORY: '/Chat/latest',
                GET_CHAT: '/Chat/',
                SEND_MESSAGE: '/Chat/',
                CREATE_CHAT: '/Chat/new',
                NEW_CHAT: '/Chat/new',
                WIDGET_CHAT: '/Embed/widget/chat/message',
            },
            FILE: {
                UPLOAD_SINGLE: '/File/single/',
                GET_FILES: '/File/list/',
                GET_UPLOADING_FILES: '/File/uploading/',
                DELETE_CHAT_FILE:"/File/"
            },
            USER: {
                NEW_USER:'/users/login'
            },
        }
    }
}

export class TEXT_CONSTANTS{
    public static get MAIN_PAGE() {
        return [
            "What would you like to know?",
            "How can I assist you today?",
            "What information are you looking for?",
            "What question can I help answer?",
            "What topic would you like to explore?",
            "What can I do for you?",
            "What would you like to learn about?",
            "How may I help you?",
            "What's on your mind?",
            "What would you like me to explain?",
        ];
    }
}

const getCurrentBaseUrl = (): string => {
    return API_CONFIG.BASE_URL.API_URL;
    // if (process.env.NODE_ENV === 'production') {
    //     return API_CONFIG.BASE_URL.PROD;
    // }
    // return API_CONFIG.BASE_URL.DEV;
};

// export const API_BASE_URL = getCurrentBaseUrl();
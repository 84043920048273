"use client"
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {API_CONFIG} from "@/constants/apiConstants";

export function getMachineId() {
    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('MachineId', machineId);
    }
    return machineId;
}

export const baseWidgetQuery = fetchBaseQuery({
    baseUrl: API_CONFIG.BASE_URL.API_URL,
    prepareHeaders: (headers) => {
        const token = getMachineId();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
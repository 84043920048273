// app/login/components/toast-message.tsx
'use client';

import { useEffect } from 'react';
import { toast } from 'sonner';

interface ToastMessageProps {
    error?: string;
}

export function ToastMessage({ error }: ToastMessageProps) {
    useEffect(() => {
        if (error) {
            toast.error(
                error === 'session_expired'
                    ? 'Your session has expired. Please log in again.'
                    : 'Authentication error'
            );
        }
    }, [error]);

    return null;
}

import {User} from "next-auth";

export function UserHasRole(user: User, ...roles: string[]): boolean {
    for (let role of user.roles) {
        if (roles.includes(role)) return true;
    }
    return false;
}

export function UserIsAdmin(user: User): boolean {
    return user.roles.includes("Admin");
}

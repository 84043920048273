"use client";

import {Session} from "next-auth";
import NavigationBar from "@/components/navigation-bar/NavigationBar";
import {Button} from "@/components/button/common-button/Button";
import {UserIsAdmin} from "@/core/utils";

function alerte(): void {
    alert("XD");
    console.log("xdddd")
}


interface SignedButtonsProps {
    session: Session;
    signOutSSOAction: () => Promise<void>;
}

interface UnSignedButtonsProps {
    signInSSOAction: () => Promise<void>;
    signInSSOMSAction: () => Promise<void>;
}

export function SignedButtons({session, signOutSSOAction}: SignedButtonsProps) {
    const buttons = function AdminButtons() {
        if (UserIsAdmin(session.user))
            return (<>
                <Button onClick={() => {
                    navigator.clipboard.writeText(session.id_token).then();

                    // Alert the copied text

                }} type={"button"}>Copy to clipboard</Button>
                <Button onClick={() => {
                    alert(JSON.stringify(session, null, 2))
                    // Alert the copied text

                }} type={"button"}>Show session</Button>
                <div>
                    {(UserIsAdmin(session.user)) ? "IS ADMIN" : "Is user"}
                </div>
            </>)
        return (<></>)
    };
    return (
        <NavigationBar>
            Hello {session.user!.name}
            <Button formAction={signOutSSOAction}>Sign Out</Button>
            {buttons()}
        </NavigationBar>
    )
}

export function UnSignedButtons({signInSSOMSAction, signInSSOAction}: UnSignedButtonsProps) {
    return (<></>);
    // return (
    //     <NavigationBar>
    //         <div className="flex flex-col gap-1">
    //             <Button formAction={signInSSOMSAction}>Sign In With Microsoft</Button>
    //             <Button formAction={signInSSOAction}>Sign In with SSO</Button>
    //         </div>
    //     </NavigationBar>
    // )
}